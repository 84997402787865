const base = `https://cistonosoplena.ml/api`;

export async function get(date: string): Promise<null | number> {
  let res = await fetch(`${base}/get?date=${encodeURIComponent(date)}`);

  if (res.status == 404) return null;

  return Number(await res.text());
}

export async function set(date: string, value: number): Promise<void> {
  await fetch(`${base}/set`, {
    method: "POST",
    body: JSON.stringify({
      date,
      value
    })
  });
}

export async function list(): Promise<string[]> {
  let res = await fetch(`${base}/list`);

  return (await res.json()) as string[];
}
