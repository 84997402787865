export const canvas = document.querySelector("canvas");
export const button = document.querySelector("button");
export const h1 = document.querySelector("h1");
export const h3 = document.querySelector("h3");
export const dialog = {
  self: document.querySelector("dialog"),
  date: document.querySelector("dialog input[type='date']") as HTMLInputElement,
  amount: document.querySelector("dialog input[type='number']") as HTMLInputElement
};

window.Dialog = dialog;
