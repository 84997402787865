import { Chart } from "chart.js/auto";
import * as Api from "./api";
import * as Dom from "./dom";

declare global {
  interface Window {
    Dialog: unknown;
  }
}

let keys: string[] = [];
let values: number[] = [];

Dom.button.onclick = async () => {
  Dom.dialog.date.valueAsDate = new Date();
  Dom.dialog.amount.valueAsNumber = 0;

  Dom.dialog.self.showModal();
};

Dom.dialog.self.onclose = async () => {
  let date = Dom.dialog.date.valueAsDate;
  let amount = Dom.dialog.amount.valueAsNumber;

  console.log(date, amount);

  if (!date) return;
  if (!amount || isNaN(amount)) return;

  let stringDate = date.toDateString().substring(4);

  let current = await Api.get(stringDate);
  if (current) amount += Number(current);

  await Api.set(stringDate, amount);

  alert("OK");
};

async function main() {
  console.log("Listing keys");

  keys = await Api.list();

  console.log("Listed keys", keys);

  console.log("Fetching values");

  for (let i = 0; i < keys.length; i++) {
    Dom.h3.textContent = `Stahování: ${i + 1}/${keys.length}`;

    values[i] = Number(await Api.get(keys[i]));
  }

  Dom.h3.textContent = "";

  let data = {
    labels: keys,
    datasets: [
      {
        label: "Kapesníky za den",
        data: values,
        borderWidth: 1
      }
    ]
  };

  let chart = new Chart(Dom.canvas, {
    type: "bar",
    data,
    options: {
      scales: {
        y: {
          beginAtZero: true
        }
      }
    }
  });

  console.log("Chart", chart);
}

main();
